<template>
  <v-alert
    type="error"
    :text="textAlert"
    v-if="showAlert"
  ></v-alert>
  <div>
    <v-data-table
      :loading="loading" 
      :headers="headers"
      :items="miembros"
      :search="search"
      :sort-by="[{ key: 'nom_apellidos', order: 'asc' }]"
      no-data-text="No hay miembros para mostrar"
      v-model:page="page"
      :items-per-page="itemsPerPage"
    >
      <template v-slot:loading>
        <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
      </template>

      <template v-slot:top>
        <v-toolbar
          color="transparent"
        >
        <div class="text-center">
          <v-menu
            v-model="showFiltros"
            :close-on-content-click="false"
            location="end"
          >
            <template v-slot:activator="{ props }">
                <v-btn 
                  v-if="idRol == 1 || idRol == 2"
                  color="primary"
                  v-bind="props"
                  :disabled="loading"
                >
                  FILTRAR
                </v-btn>
            </template>
          
            <v-card 
              title="Filtros"
              height="400"
              width="300"
              style="overflow: hidden;"
            >
              
              <v-divider></v-divider>
              <v-list>
                  <v-list-group value="Ocupaciones">
                      <template v-slot:activator="{ props }">
                          <v-list-item
                              v-bind="props"
                              title="Ocupación"
                          ></v-list-item>
                      </template>
                      
                      <v-virtual-scroll
                        :height="150"
                        :items="catalogos.ocupaciones"
                      >
                        <template v-slot:default="{ item }">
                          <!--v-row no-gutters>
                              <v-col cols="12" v-for="(ocupacion, i) in catalogos.ocupaciones" :key="i">
                                  <v-checkbox :v-model="ocupacion.selected" :value="ocupacion.id" :label="ocupacion.nom_ocupacion" hide-details density="compact"></v-checkbox>
                              </v-col>
                          </v-row-->
                          <template v-for="(ocupacion, i) in catalogos.ocupaciones" :key="i">
                              <v-list-item
                                  :title="ocupacion.nom_nombre"
                                  v-if="!selected.includes(ocupacion)"
                                  @click="selected.push(ocupacion)"
                              ></v-list-item>
                          </template>
                        </template>
                      </v-virtual-scroll>
                  </v-list-group>

                  <v-list-group value="congregaciones">
                      <template v-slot:activator="{ props }">
                          <v-list-item
                              v-bind="props"
                              title="Casa de Dios"
                          ></v-list-item>
                      </template>
                      <v-virtual-scroll
                        :height="150"
                        :items="catalogos.congregaciones"
                      >
                        <template v-slot:default="{ item }">
                          <!--v-row no-gutters>
                              <v-col cols="12" v-for="(congregacion, i) in catalogos.congregaciones" :key="i">
                                  <v-checkbox :v-model="congregacion.selected" :value="congregacion.id" :label="congregacion.nom_congregacion" hide-details density="compact"></v-checkbox>
                              </v-col>
                          </v-row-->
                          <template v-for="(congregacion, i) in catalogos.congregaciones" :key="i">
                              <v-list-item
                                  :title="congregacion.nom_nombre"
                                  v-if="!selected.includes(congregacion)"
                                  @click="selected.push(congregacion)"
                              ></v-list-item>
                          </template>
                        </template>
                      </v-virtual-scroll>
                  </v-list-group>

                  <v-list-group value="Ministerios">
                      <template v-slot:activator="{ props }">
                          <v-list-item
                              v-bind="props"
                              title="Ministerio"
                          ></v-list-item>
                      </template>
                      <v-virtual-scroll
                        :height="150"
                        :items="catalogos.ministerios"
                      >
                        <template v-slot:default="{ item }">
                          <!--v-row no-gutters>
                              <v-col cols="12" v-for="(ministerio, i) in catalogos.ministerios" :key="i">
                                  <v-checkbox @click="selected.push(ministerio)" :value="ministerio.id" :label="ministerio.nom_ministerio" hide-details density="compact"></v-checkbox>
                              </v-col>
                          </v-row-->
                          <template v-for="(ministerio, i) in catalogos.ministerios" :key="i">
                              <v-list-item
                                  :title="ministerio.nom_nombre"
                                  v-if="!selected.includes(ministerio)"
                                  @click="selected.push(ministerio)"
                              ></v-list-item>
                          </template>
                        </template>
                      </v-virtual-scroll>
                  </v-list-group>
              </v-list>
          
          <v-card 
            class="mx-auto"
            height="120"
            max-width="280"
            variant="outlined"
            color="grey"
          >
              <v-row
                  align="center"
                  justify="start"
              >
                  <v-col
                  v-for="(selection, i) in selections"
                  :key="selection.id"
                  cols="auto"
                  class="py-4 pe-0"
                  >
                  <v-chip
                      :disabled="loading"
                      closable
                      @click:close="selected.splice(i, 1)"
                  >

                      {{ selection.nom_nombre }}
                  </v-chip>
                  </v-col>
              </v-row>
          </v-card>
          
              <v-card-actions>
                <v-spacer></v-spacer>
              
                <v-btn
                  variant="text"
                  @click="closeFilter()"
                >
                  Borrar Todo
                </v-btn>
                <v-btn
                  color="primary"
                  variant="text"
                  @click="loadFilter()"
                >
                  Aplicar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          </div>

          <v-spacer></v-spacer>
          
          <v-text-field
              v-model="search"
              label="Buscar Miembros"
              append-inner-icon="mdi-account-search"
              single-line
              variant="underlined"
              hide-details
              :disabled="loading"
          ></v-text-field>
          
          <v-spacer></v-spacer>
          <v-tooltip 
              text="Agregar Miembro"
              location="bottom"
          >
              <template v-slot:activator="{ props }">
                  <v-btn 
                      v-bind="props"
                      icon="mdi-account-multiple-plus"
                      elevation="4"
                      color="primary"
                      variant="flat"
                      :disabled="loading"
                      @click="addMiembro()"
                  ></v-btn>
              </template>
          </v-tooltip>
      </v-toolbar>
      </template>

      <template v-slot:item.imagen="{ item }">
        <div class="text-center">
          <v-menu
            :close-on-content-click="true"
            location="end"
          >
            <template v-slot:activator="{ props }">
              <v-row class="text-center">
                <v-col cols="12">
                  <!--v-avatar v-bind="props" :image="require('../../assets/img/'+item.id+'.jpg')" size="45"-->
                  <v-avatar v-bind="props" :image="'/img/'+item.id+'.jpg'" size="45">
                    <template v-slot:placeholder>
                      <div class="d-flex align-center justify-center fill-height">
                        <v-progress-circular
                          color="grey"
                          indeterminate
                        ></v-progress-circular>
                      </div>
                    </template>
                  </v-avatar>
                </v-col>
              </v-row>
            </template>
          
            <v-card 
              :title="item.nom_miembro+' '+item.nom_apellidoPaterno"
              style="overflow: hidden;"
              elevation="8"
              height="250"
              width="250"
            >        
              <!--v-img :src="require('../../assets/img/'+item.id+'.jpg')"-->
              <v-img :src="'/img/'+item.id+'.jpg'">
                <template v-slot:placeholder>
                  <div class="d-flex align-center justify-center fill-height">
                    <v-progress-circular
                      color="grey"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                </template>
              </v-img>
            </v-card>
          </v-menu>
          </div>
        
      </template>

      <template v-slot:item.actions="{ item }">
        <v-row align="center" justify="center" no-gutters>
          <v-col class="m2" cols="auto">
              <v-tooltip 
                  text="Ver"
                  location="bottom"
              >
                  <template v-slot:activator="{ props }">
                      <v-btn 
                          v-bind="props"
                          icon="mdi-account-details-outline"
                          elevation="4"
                          color="teal"
                          variant="flat"
                          size="small"
                          class="ma-1"
                          @click="showMiembroModal(item)"
                      ></v-btn>
                  </template>
              </v-tooltip>
          </v-col>
          <v-col cols="auto">
              <v-tooltip 
                  text="Editar"
                  location="bottom"
              >
                  <template v-slot:activator="{ props }">
                      <v-btn 
                          v-bind="props"
                          icon="mdi-account-edit-outline"
                          elevation="4"
                          color="cyan-darken-1"
                          variant="flat"
                          size="small"
                          class="ma-1"
                          @click="editMiembroModal(item)"
                          :disabled="item.inactivo == 2"
                      ></v-btn>
                  </template>
              </v-tooltip>
          </v-col>
          <v-col cols="auto">
              <v-tooltip 
                  text="Eliminar"
                  location="bottom"
              >
                  <template v-slot:activator="{ props }">
                      <v-btn 
                          v-bind="props"
                          icon="mdi-delete-outline"
                          elevation="4"
                          color="red"
                          variant="flat"
                          size="small"
                          class="ma-1"
                          @click="deleteMiembroModal(item)"
                          :disabled="item.inactivo == 2 && idCongregacion != 0"
                      ></v-btn>
                  </template>
              </v-tooltip>
          </v-col>
        </v-row>
      </template>

      <template v-slot:bottom="data">
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            rounded="circle"
          ></v-pagination>
        </div>
        <slot name="bottom" :any_name="internalData = data" />
        <!--div>Pagination: {{ internalData }}</div-->
      </template>
    </v-data-table>
    <add-miembro-modal :idCongregacionProp="idCongregacion"></add-miembro-modal>
    <delete-miembro-modal></delete-miembro-modal>
  </div>
</template>
<script>
import axios from "axios";
import addMiembroModal from './AddMiembro.vue';
import deleteMiembroModal from './DeleteMiembroModal.vue';
import emitter from "../../plugins/mitt";

  export default {
    name: 'MiembroView',
    props: ['idCongregacion', "idRol"],

    components: {
      addMiembroModal,
      deleteMiembroModal
    },

    data () {
      return {
        search:'',
        textAlert: '',
        showAlert: false,
        showFiltros: false,
        showImagen: false,
        menu: false,
        message: false,
        loadingMiembros: false,
        loadingOcupaciones: false,
        loadingMinisterios: false,
        loadingCongregaciones: false,
        itemsPerPage: 10,
        page: 1,
        internalData: {},
        catalogos: {
            ocupaciones:[],
            congregaciones: [],
            ministerios: []
        },
        selected:[],
        headers: [
            { title: '', key: 'imagen'},
            {
            title: 'Apellido',
            align: 'start',
            key: 'nom_apellidos',
            },
            { title: 'Nombre', key: 'nom_miembro' },
            { title: 'Edad', key: 'num_edad' },
            { title: 'Ministerio', key: 'nom_ministerio' },
            { title: 'Casa de Dios', key: 'nom_congregacion' },
            { title: 'Celular', key: 'num_celular'},
            { title: '', key: 'actions', sortable: false }
        ],
        miembros: [],
        urlApi: process.env.VUE_APP_ENV_MIEMBROS_API,
        urlApiCatalogos: process.env.VUE_APP_ENV_CATALOGOS_API
      }
    },

    mounted(){
      var self = this
      emitter.on('addMiembroModalClosed',function(data){
        if(data.success){
          self.loadingMiembros = false
          self.loadMiembros()
        }else{
          self.textAlert = data.message
          self.showAlert = true
          setTimeout(() => self.showAlert = false, 5000);
        }
      });

      emitter.on('deleteMiembroModalClosed',function(data){
        if(data.success){
          self.loadingMiembros = false
          self.loadMiembros()
        }else{
          self.textAlert = data.message
          self.showAlert = true
          setTimeout(() => self.showAlert = false, 5000);
        }
      });
    },

    created() {
      this.loadCatalogos()
      if(this.idCongregacion == 0){
        this.loadMiembros()
      }else{
        this.selected = [{id: this.idCongregacion, tipo: 2}]
        this.loadFilter()
      }
    },

    computed: {
      pageCount () {
        //if(this.internalData.items)
          return this.internalData.pageCount
        /*else
          return Math.ceil(this.miembros.length / this.itemsPerPage)*/
        
      },
      loading () {
        if(this.loadingMiembros && this.loadingOcupaciones && this.loadingMinisterios && this.loadingCongregaciones){
            return false
        }else{
            return true
        }
      },
      selections () {
        const selections = []

        for (const selection of this.selected) {
          selections.push(selection)
        }

        return selections
      }
    },
    
    methods: {
      loadFilter () {
        var self = this

        if(self.selected.length > 0){
          self.loadingMiembros = false
          self.showFiltros = false
          axios.post(self.urlApi+"miembrosFilter", self.selected, {'headers': {'Authorization': 'Bearer ' +localStorage.getItem('SesionID')}}).then((result) => {
            self.miembros = result.data.data
            self.loadingMiembros = true
          }).catch(error=>{
            self.loadingMiembros = true
            console.log(error)
          })
        }else{
          self.showFiltros = false
        }
      },

      closeFilter() {
        var self = this

        self.showFiltros = false
        if(self.selected.length > 0){
          self.selected = []
          self.loadingMiembros = false

          axios.get(self.urlApi+"miembros", {'headers': {'Authorization': 'Bearer ' +localStorage.getItem('SesionID')}}).then((result) => {
            self.miembros = result.data.data
            self.loadingMiembros = true
          }).catch(error=>{
            self.loadingMiembros = true
            console.log(error)
          })
        }
      },

      addMiembro() {
        var params = {
          show: true
        }

        emitter.emit("addMiembroModal", params);
      },

      loadCatalogos() {
        this.loadMinisterios()
        this.loadOcupaciones()
        if(this.idCongregacion == 0){
          this.loadCongregaciones()
        }else{
          this.loadingCongregaciones = true
        }
      },

      loadMinisterios() {
        var self = this

        axios.get(self.urlApiCatalogos+"ministerios", {'headers': {'Authorization': 'Bearer ' +localStorage.getItem('SesionID')}}).then((result) => {
          self.catalogos.ministerios = []
          result.data.data.forEach(ministerio => {
            self.catalogos.ministerios.push({id: ministerio.id, nom_nombre: ministerio.nom_ministerio, tipo: 3})
          });
          self.loadingMinisterios = true
        }).catch(error=>{
          self.loadingMinisterios = true
          console.log(error)
        })
      },

      loadOcupaciones() {
        var self = this

        axios.get(self.urlApiCatalogos+"ocupaciones", {'headers': {'Authorization': 'Bearer ' +localStorage.getItem('SesionID')}}).then((result) => {
          self.catalogos.ocupaciones = []
          result.data.data.forEach(ocupacion => {
            self.catalogos.ocupaciones.push({id: ocupacion.id, nom_nombre: ocupacion.nom_ocupacion, tipo: 1})
          });
          self.loadingOcupaciones = true
        }).catch(error=>{
          self.loadingOcupaciones = true
          console.log(error)
        })
      },

      loadCongregaciones() {
        var self = this

        axios.get(self.urlApiCatalogos+"congregaciones", {'headers': {'Authorization': 'Bearer ' +localStorage.getItem('SesionID')}}).then((result) => {
          self.catalogos.congregaciones = []
          result.data.data.forEach(congregacion => {
            self.catalogos.congregaciones.push({id: congregacion.id, nom_nombre: congregacion.nom_congregacion, tipo: 2})
          });
          self.loadingCongregaciones = true
        }).catch(error=>{
          self.loadingCongregaciones = true
          console.log(error)
        })
      },

      loadMiembros() {
        var self = this
        axios.get(self.urlApi+"miembros", {'headers': {'Authorization': 'Bearer ' +localStorage.getItem('SesionID')}}).then((result) => {
          self.miembros = result.data.data
          self.loadingMiembros = true
        }).catch(error=>{
          self.loadingMiembros = true
          self.textAlert = "Ah ocurrido una excepción al cargar los Miembros, favor de intentarlo nuevamente."
          self.showAlert = true
          setTimeout(function(){
              self.showAlert = false;
              emitter.emit("returnHome");
          }, 5000);
          console.log(error)
        })
      },

      showMiembroModal(miembro){
        var params = {
          show: true,
          isEdit: false,
          miembro: miembro
        }

        emitter.emit("showMiembroModal", params);
      },

      editMiembroModal(miembro){
        var params = {
          show: true,
          isEdit: true,
          miembro: miembro
        }

        emitter.emit("showMiembroModal", params);
      },

      deleteMiembroModal(miembro){
        var params = {
          show: true,
          id_miembro: miembro.id,
          isGeneral: this.idCongregacion == 0 ? 1 : 0,
          nombre: miembro.nom_completo
        }
        emitter.emit("deleteMiembroModal", params);
      }
    }
  }
</script>
<style>
.v-table__wrapper{
    overflow-y: hidden;
}
</style>