<template>
    <v-row justify="center">
      <v-dialog
        v-model="showModal"
        persistent
        max-height="600"
      >
        <v-card class="mx-auto">
          <div>
          <v-card-title>
            <span class="text-h5">Eliminar Miembro</span>
          </v-card-title>
          <v-card-text>
            <br>
            <div class="text-h6">¿Deseas dar de baja a {{nom_miembro}}?</div>
            <br><br>
            <v-form ref="form">
              <v-row>
                <v-col
                  cols="12"
                >
                <v-radio-group 
                  label="Selecciona el motivo de la Baja: " 
                  v-model="idBaja"
                  :rules="[v => !!v || 'El motivo es obligatorio.']"
                >
                  <v-radio label="Baja Temporal" :value="1"></v-radio>
                  <v-radio label="Baja Permanente" :value="2"></v-radio>
                  <v-radio label="Fallecimiento" :value="3"></v-radio>
                </v-radio-group>
                </v-col>
              </v-row>
              <v-row no-gutters v-if="showCalendar">
                <v-col
                  cols="12"
                  md="7"
                >
                  <Datepicker                    
                    class="date"
                    placeholder="Fecha de Fallecimiento" 
                    v-model="fecha" 
                    :format="format"
                    select-text="Aceptar"
                    cancel-text="Cancelar"
                    week-start="0"
                    :min-date="minDate"
                    :max-date="maxDate"
                    locale="es-mx"
                    :enable-time-picker="false"
                    teleport-center
                  > 
                  </Datepicker>
                </v-col>
              </v-row>
              <p v-show="showAlertFecha" class="text-caption text-red-darken-4">La fecha es Obligatoria.</p>  
            </v-form>
            <br><br>
            <div class="text-subtitle-2 text-red-darken-4" v-if="isGeneral == 0">*Las bajas deberan ser autorizadas por Administracion General</div>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="info"
            text
            @click="close()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="info"
            text
            @click="save()"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </div>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  
  <script>
  import axios from "axios";
  import emitter from "../../plugins/mitt";
  import Datepicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css';

  
  export default {
    name: "DeleteMiembroModal",

    components: {
      Datepicker
    },
  
    data() {
      return {
        showModal: false,
        idMiembro: 0,
        isGeneral: 0,
        idBaja: 0,
        nom_miembro: '',
        fecha: '',
        showAlertFecha: false,
        format: (date) => {
          const day = date.getDate();
          const month = date.getMonth() + 1;
          const year = date.getFullYear();

          return `${year}/${month}/${day}`;
        },
        maxDate: '1900/01/01',
        minDate: '1900/01/01',
        urlApi: process.env.VUE_APP_ENV_MIEMBROS_API
      };
    },
  
    mounted(){
      var self = this 
  
      const date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      self.maxDate = `${year}/${month}/${day}`;
      self.minDate = `${year-1}/${month}/${day}`;

      emitter.on('deleteMiembroModal',function(data){
        self.idMiembro = data.id_miembro
        self.isGeneral = data.isGeneral
        self.showModal = data.show
        self.nom_miembro = data.nombre
      });
  
    },

    computed: {
      showCalendar(){
          if(this.idBaja == 3){
              return true
          }else{
              return false
          }
      }
    },

    watch: {
      fecha: function() {
        if(this.fecha != ''){
          this.showAlertFecha = false
        }
      }
    },

  
    methods: {    
      close(){
        this.showModal = false
        this.idMiembro = 0
        this.isGeneral = 0
        this.idBaja = 0
        this.nom_miembro = ''
        this.fecha = ''
        this.showAlertFecha = false
      },
  
      async save(){
        var self = this
        const { valid } = await this.$refs.form.validate()
        if(valid){
          if(self.idBaja == 3 && self.fecha == ''){
            self.showAlertFecha = true
          }else{
            var params = {
              id: self.idMiembro,
              motivo_baja: self.idBaja,
              inactivo: self.isGeneral == 1 ? 1 : 2,
              fecha: self.fecha == '' ? '1900-01-01' : self.fecha
            }
            
            axios.delete(self.urlApi+"miembros", {'headers': {'Authorization': 'Bearer ' +localStorage.getItem('SesionID')},
              data: params
            }).then((result) => {
              emitter.emit("deleteMiembroModalClosed", result.data);
              self.close()
            }).catch(error=>{
              console.log(error)
            });
          }
        }
      }
    }
  }
  </script>
  