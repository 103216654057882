<template>
    <v-row justify="center">
      <v-dialog
        v-model="showModal"
        persistent
        max-height="300"
      >
        <v-card class="mx-auto">
          <div>
          <v-card-title>
            <span class="text-h4">Confirmar Ministerio</span>
          </v-card-title>
          <v-card-text>
            <div class="text-subtitle-2">Se ha detectado que el miembro cuenta con mas de un ministerio.<br> 
                Favor de confirmar el/los Ministerio(s), del cual es encargado.</div>
            <br>
            <v-form ref="form">
              <v-row>
                <v-col
                  cols="12"
                >
                    <v-autocomplete
                      v-model="ministerio"
                      class="mb-2"
                      label="Ministerio y/o Comisión"
                      variant="underlined"
                      item-title="nom_ministerio"
                      :items="catalogos.ministerio"
                      return-object
                      multiple
                      chips
                      closable-chips
                      required
                      :rules="[v => !!v || 'Debes Seleccionar al menos un Ministerio.']"
                    ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="info"
            text
            @click="save()"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </div>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  
  <script>
import emitter from "../../plugins/mitt";
  
  export default {
    name: "ConfirmaEncargadoModal",
  
    data() {
      return {
        showModal: false,
        ministerio: [],
        catalogos: {
            ministerio: []
        },
      };
    },
  
    mounted(){
      var self = this 
  
      emitter.on('confirmaEncargadoModal',function(data){
        self.showModal = data.show
        self.catalogos.ministerio = data.ministerios
        data.ministerios.forEach(element => {
            //if(Object.hasOwn(element, 'isEncargado')){
                if(element.isEncargado == 1){
                    self.ministerio.push(element)
                }
            //} 
        });
      });
  
    },
  
    methods: {    
      close(){
        this.showModal = false
        this.ministerio = []
        this.catalogos.ministerio = []
      },
  
      async save(){
        const { valid } = await this.$refs.form.validate()
        if(valid){
            var self = this
            self.catalogos.ministerio.forEach(elementCat => {
                elementCat.isEncargado = 0
                self.ministerio.forEach(element => {
                    if(elementCat.id == element.id){
                        elementCat.isEncargado = 1
                    }
                });
            });

            var params = {
                ministerio: self.catalogos.ministerio
            }

            emitter.emit("confirmaEncargadoModalClosed",params);
            self.close()
        }
      }
    }
  }
  </script>
  