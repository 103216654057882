<template>
  <v-row justify="center">
    <v-dialog
      v-model="showModal"
      persistent
      max-width="400"
    >
      <v-card class="mx-auto" width="300">
        <v-skeleton-loader
          v-if="loading"
          type="list-item-three-line, actions"
        ></v-skeleton-loader>
        <div v-else>
          <v-card-title>
            <span class="text-h5">Buscar Código Postal</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="form"><!--
              @submit.prevent="login()"
            -->
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-autocomplete
                    v-model="pais"
                    :items="catalogos.paises"
                    item-title="nom_pais"
                    required
                    class="mb-2"
                    label="País"
                    variant="underlined"
                    :rules="[v => !!v || 'El País es Obligatorio.']"
                    return-object
                    disabled
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-autocomplete
                    v-model="estado"
                    :items="catalogos.estados"
                    item-title="nom_estado"
                    required
                    class="mb-2"
                    label="Estado"
                    variant="underlined"
                    :rules="[v => !!v || 'El Estado es Obligatorio.']"
                    return-object
                    @update:modelValue="changeEstado()"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-autocomplete
                    v-model="ciudad"
                    :items="catalogos.ciudades"
                    item-title="nom_municipio"
                    required
                    class="mb-2"
                    label="Ciudad"
                    variant="underlined"
                    :rules="[v => !!v || 'La Ciudad es Obligatoria.']"
                    :loading="loadingCiudad"
                    return-object
                    @update:modelValue="changeCiudad()"
                    :disabled="disableCiudad"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-autocomplete
                    v-model="colonia"
                    :items="catalogos.colonias"
                    item-title="nom_colonia"
                    required
                    class="mb-2"
                    label="Colonia"
                    variant="underlined"
                    :rules="[v => !!v || 'La Colonia es Obligatoria.']"
                    :loading="loadingColonia"
                    return-object
                    @update:modelValue="changeColonia()"
                    :disabled="disableColonia"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="12"
                >
                  <p v-show="cp != ''">Tu Código Postal es: {{ cp }}</p>
                  <p v-show="cp == '' && !disableColonia" class="text-red">*Si no encuentras la colonia, favor de reportarlo a un Administrador.</p>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="info"
              text
              @click="close()"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="info"
              text
              :loadSave="isSaving"
              @click="save()"
            >
              Aceptar
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import emitter from "../../plugins/mitt";

export default {
  name: "searchCP",

  data() {
    return {
      showModal: false,
      cp: '',
      pais: null,
      estado: null,
      ciudad: null,
      colonia: null,
      catalogos: {
        paises: [],
        estados: [],
        ciudades: [],
        colonias: []
      },
      loadingPais: false,
      loadingEstado: false,
      loadingCiudad: false,
      loadingColonia: false,
      disableCiudad: false,
      disableColonia: false,
      isSaving: false,
      urlApi: process.env.VUE_APP_ENV_LOCALIZACION_API,
        
    };
  },

  mounted(){
    var self = this

    emitter.on('searchCPModal',function(data){
      self.showModal = data.show
      self.loadCatalogos()
    });

  },

  computed: {
    loading(){
        if(!this.loadingPais && !this.loadingEstado){
            return false
        }else{
            return true
        }
    }
  },

  methods: {
    loadCatalogos (){
      this.loadingPais = true
      this.disableCiudad = true
      this.disableColonia = true
      this.loadPais()
    },

    loadPais (){
      var self = this

      axios.get(self.urlApi+"paises", {'headers': {'Authorization': 'Bearer ' +localStorage.getItem('SesionID')}}).then((result) => {
        self.catalogos.paises = result.data.data
        result.data.data.forEach(pais => {
          if(pais.nom_pais == 'México'){
            self.pais = pais
          }
        });
        self.loadingEstado = true
        self.loadingPais = false
        self.loadEstado()
      }).catch(error=>{
        console.log(error)
      })
    },

    loadEstado (){
      var self = this
      var params = {
        id_pais: self.pais.id
      }

      axios.post(self.urlApi+"estados", params,{
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('SesionID')
        }
      }).then((result) => {
        self.catalogos.estados = result.data.data
        self.loadingEstado = false
      }).catch(error=>{
        console.log(error)
      })
    },

    loadCiudad (){
      var self = this
      var params = {
        id_estado: self.estado.id
      }

      axios.post(self.urlApi+"municipios", params,{
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('SesionID')
        }
      }).then((result) => {
        self.catalogos.ciudades = result.data.data
        self.loadingCiudad = false
        self.disableCiudad = false
      }).catch(error=>{
        console.log(error)
      })
    },

    loadColonias (){
      var self = this
      var params = {
        id_municipio: self.ciudad.id
      }

      axios.post(self.urlApi+"colonias", params,{
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('SesionID')
        }
      }).then((result) => {
        self.catalogos.colonias = result.data.data
        self.loadingColonia = false
        self.disableColonia = false
      }).catch(error=>{
        console.log(error)
      })
    },

    loadCP (){
      this.cp = this.colonia.codigo_postal
    },

    changeEstado (){
      this.disableCiudad = true
      this.disableColonia = true
      this.ciudad = null
      this.colonia = null
      this.cp = ''
      this.catalogos.ciudades = []
      this.catalogos.colonias = []
      if(this.estado != null){
        this.loadingCiudad = true
        this.loadCiudad()
      }
    },

    changeCiudad (){
      this.disableColonia = true
      this.colonia = null
      this.catalogos.colonias = []
      this.cp = ''
      if(this.ciudad != null){
        this.loadingColonia = true
        this.loadColonias()
      }
    },

    changeColonia (){
      this.cp = ''
      if(this.colonia != null){
        this.loadCP()
      }
    },

    close(){
      this.showModal = false
      this.cp = ''
      this.pais = null
      this.estado = null
      this.ciudad = null
      this.colonia = null
      this.isSaving = false
    },

    async save() {
      const { valid } = await this.$refs.form.validate()
      if (valid){
        this.isSaving = true
        var params = {
          cp: this.cp,
          pais: this.pais,
          estado: this.estado,
          ciudad: this.ciudad,
          colonia: this.colonia
        }

        emitter.emit("searchCPModalClosed", params);

        this.close()
      }
    }
  }
};
</script>
