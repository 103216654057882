<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="showModal"
        persistent
        max-width="1200"
      >
        <v-card class="mx-auto" width="1100">
          
          <v-alert
            type="error"
            :text="textAlert"
            v-if="showAlert"
          ></v-alert>
          <v-skeleton-loader
            v-if="loading"
            type="list-item-two-line, list-item-three-line, actions"
          ></v-skeleton-loader>
          <div v-else>
          <v-card-title>
            <span class="text-h5">Agregar Miembro</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="form"><!--
              @submit.prevent="login()"
            -->
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-card 
                    style="overflow: hidden; border-radius: 15px"
                    elevation="8"
                    height="160"
                    width="150"
                    color="grey-lighten-2"
                  >        
                    <v-img 
                      :src="'/img/'+nomImagen+'.jpg'"
                      height="160"
                    >
                    <!--v-img 
                      :src="require('../../assets/img/undefined.jpg')"
                      height="160"
              -->
                      <template v-slot:placeholder>
                        <div class="d-flex align-center justify-center fill-height">
                          <v-progress-circular
                            color="grey"
                            indeterminate
                          ></v-progress-circular>
                        </div>
                      </template>
                      <v-row class="fill-height ma-0 align-end justify-center" no-gutters>
                        <v-col>
                          <v-card
                            class="mx-auto"
                            style="opacity: 0.5;"
                            tabindex="0"
                            align="center"
                            link
                            rounded="0"
                            :disabled="isShow"
                            @click="openCapturarImagenModal()"
                          >
                            <i aria-hidden="true"><v-icon icon="mdi-camera"></v-icon></i>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-img>
                  </v-card>
                  <p v-show="showAlertImg" class="text-caption text-red-darken-4">La imagen es Obligatoria.</p>
                </v-col>
                <v-col
                  cols="12"
                  md="10"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="apellidoPaterno"
                        :rules="[v => !v || v.length <= 25 || 'El Apellido Paterno debe contener máximo 25 caracteres.']"
                        class="mb-2"
                        label="Apellido Paterno"
                        placeholder="Apellido Paterno"
                        variant="underlined"
                        counter=25
                        :disabled="isShow"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="apellidoMaterno"
                        class="mb-2"
                        :rules="[v => !v || v.length <= 25 || 'El Apellido Materno debe contener máximo 25 caracteres.']"
                        label="Apellido Materno"
                        placeholder="Apellido Materno"
                        variant="underlined"
                        counter=25
                        :disabled="isShow"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="nombre"
                        required
                        :rules="nombreRegla"
                        class="mb-2"
                        label="Nombre(s)"
                        placeholder="Nombre(s)"
                        variant="underlined"
                        counter=50
                        :disabled="isShow"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <Datepicker                    
                        class="date"
                        placeholder="Fecha de Nacimiento" 
                        v-model="fecNacimiento" 
                        :format="format"
                        select-text="Aceptar"
                        cancel-text="Cancelar"
                        week-start="0"
                        min-date="1900-01-01"
                        :max-date="maxDate"
                        locale="es-mx"
                        :enable-time-picker="false"
                        :rules="[v => !!v || 'La Fecha de Nacimiento es Obligatoria.']"
                        :disabled="isShow"
                      > 
                      </Datepicker>
                      <p v-show="showAlertFecha" class="text-caption text-red-darken-4">La fecha de Nacimiento es Obligatoria.</p>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-autocomplete
                        v-model="estadoCivil"
                        required
                        class="mb-2"
                        label="Estado Civil"
                        variant="underlined"
                        item-title="nom_estadoCivil"
                        :items="catalogos.estadoCivil"
                        return-object
                        :rules="[v => !!v || 'El Estado Civil es Obligatorio.']"
                        :disabled="isShow"
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-autocomplete
                        v-model="genero"
                        required
                        class="mb-2"
                        label="Género"
                        variant="underlined"
                        item-title="nom_genero"
                        :items="catalogos.genero"
                        return-object
                        :rules="[v => !!v || 'El Género es Obligatorio.']"
                        :disabled="isShow"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    v-model="domCalle"
                    required
                    :rules="domCalleRegla"
                    class="mb-2"
                    label="Domicilio (Calle)"
                    placeholder="Domicilio (Calle)"
                    variant="underlined"
                    counter=100
                    :disabled="isShow"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    v-model="domNumero"
                    required
                    :rules="domNumeroRegla"
                    class="mb-2"
                    label="Domicilio (Número)"
                    placeholder="Domicilio (Número)"
                    variant="underlined"
                    counter=50
                    :disabled="isShow"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    v-model="cp"
                    required
                    class="mb-2"
                    label="C.P"
                    placeholder="C.P"
                    append-inner-icon="mdi-magnify"
                    variant="underlined"
                    :rules="cpRegla"
                    @click:append-inner="searchCP()"
                    @keyup.enter="searchCP()"
                    @update:modelValue="changeCP()"
                    :disabled="isShow"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-autocomplete
                    v-model="pais"
                    item-title="nom_pais"
                    return-object
                    required
                    class="mb-2"
                    label="País"
                    variant="underlined"
                    :rules="[v => !!v || 'El País es Obligatorio.']"
                    disabled
                    :loading="loadingPais"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-autocomplete
                    v-model="estado"
                    item-title="nom_estado"
                    return-object
                    required
                    class="mb-2"
                    label="Estado"
                    variant="underlined"
                    :rules="[v => !!v || 'El Estado es Obligatorio.']"
                    disabled
                    :loading="loadingEstado"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-autocomplete
                    v-model="ciudad"
                    item-title="nom_municipio"
                    return-object
                    required
                    class="mb-2"
                    label="Ciudad"
                    variant="underlined"
                    :loading="loadingCiudad"
                    :rules="[v => !!v || 'La Ciudad es Obligatoria.']"
                    disabled
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-autocomplete
                    v-model="colonia"
                    item-title="nom_colonia"
                    :items="catalogos.colonias"
                    return-object
                    required
                    class="mb-2"
                    label="Colonia"
                    variant="underlined"
                    :disabled="disableColonia || isShow"
                    :loading="loadingColonia"
                    :rules="[v => !!v || 'La Colonia es Obligatoria.']"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="correo"
                    :rules="[v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Correo debe ser válido.']"
                    class="mb-2"
                    label="Correo Electrónico"
                    placeholder="Correo Electrónico"
                    variant="underlined"
                    :disabled="isShow"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    v-model="telefono"
                    class="mb-2"
                    label="Teléfono"
                    placeholder="Teléfono"
                    variant="underlined"
                    :rules="[v => !v || v.length == 10 || 'Teléfono debe contener 10 Caracteres.']"
                    counter=10
                    :disabled="isShow"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    v-model="celular"
                    required
                    class="mb-2"
                    label="Celular"
                    placeholder="Celular"
                    variant="underlined"
                    :rules="[v => !v || (v.length == 10 && /[0-9]{10}/.test(v)) || 'El Número de Celular debe ser de 10 caracteres y solo permite números.']"
                    counter=10
                    :disabled="isShow"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-autocomplete
                    v-model="tipoContacto"
                    required
                    class="mb-2"
                    label="Tipo de Contacto"
                    variant="underlined"
                    item-title="nom_tipocontacto"
                    :items="catalogos.tipoContacto"
                    return-object
                    :rules="reglaContacto"
                    :disabled="isShow"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                cols="12"
                md="3"
                >
                <v-autocomplete
                v-model="congregacion"
                required
                class="mb-2"
                label="Casa de Dios a la que pertenece"
                variant="underlined"                    
                item-title="nom_congregacion"
                :items="catalogos.congregacion"
                return-object
                :rules="[v => !!v || 'La Congregación es Obligatoria.']"
                :disabled="isShow"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-autocomplete
                  v-model="ministerio"
                  class="mb-2"
                  label="Ministerio y/o Comisión"
                  variant="underlined"
                  item-title="nom_ministerio"
                  :items="catalogos.ministerio"
                  return-object
                  multiple
                  chips
                  closable-chips
                  @update:modelValue="changeMinisterio()"
                  :disabled="isShow"
                ></v-autocomplete>
              </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-row no-gutters>
                    <v-switch
                      label="Encargado/a"
                      color="primary"
                      v-model="isEncargado"    
                      hide-details
                      :disabled="disableEncargado"
                      @update:modelValue="changeEncargado()"
                    >
                    </v-switch>
                  </v-row>
                  <v-row no-gutters>
                    <v-switch
                      label="Bautizado/a"
                      color="primary"
                      v-model="isBautizado"
                      hide-details
                      :disabled="isShow"
                    >
                    </v-switch>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-autocomplete
                    v-model="ocupacion"
                    class="mb-2"
                    label="Ocupación"
                    variant="underlined"
                    item-title="nom_ocupacion"
                    :items="catalogos.ocupacion"
                    return-object                    
                    :disabled="isShow"
                  ></v-autocomplete>
                </v-col>
              </v-row>

            </v-form>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="info"
            text
            @click="close()"
            v-if="isShow"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="info"
            text
            @click="close()"
            v-else
          >
            Cancelar
          </v-btn>
          <v-btn
            color="info"
            text
            @click="save()"
            v-if="!isShow"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </div>
        </v-card>
      </v-dialog>
    </v-row>
    <search-cp-modal></search-cp-modal>
    <capturar-imagen-modal></capturar-imagen-modal>
    <confirma-encargado-modal></confirma-encargado-modal>
  </div>
</template>

<script>
import axios from "axios";
import emitter from "../../plugins/mitt";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import searchCpModal from './SearchCPModal.vue';
import capturarImagenModal from './CapturarImagenModal.vue'
import confirmaEncargadoModal from "./ConfirmaEncargadoModal.vue";

export default {
  name: "addMiembro",
  props: ['idCongregacionProp'],


  components: {
    Datepicker,
    searchCpModal,
    capturarImagenModal,
    confirmaEncargadoModal
  },

  data() {
    return {
      showModal: false,
      maxDate: '1900/01/01',
      nombre: '',
      nomImagen: 'undefined',
      nombreRegla: [
        v => !!v || 'El nombre es Obligatorio.',
        v => (v && v.length <= 50) || 'El nombre debe contener máximo 50 caracteres.',
      ],
      apellidoPaterno: '',
      apellidoMaterno: '',
      fecNacimiento: '',
      estadoCivil: null,
      genero: null,
      domCalle: '',
      domCalleRegla: [
        v => !!v || 'El Nombre de la calle es Obligatorio.',
        v => (v && v.length <= 100) || 'El Nombre de la Calle debe contener máximo 100 caracteres.',
      ],
      domNumero: '',
      domNumeroRegla: [
        v => !!v || 'El Número del Domicilio es Obligatorio.',
        v => (v && v.length <= 50) || 'El Número del Domicilio debe contener máximo 50 caracteres.',
      ],
      cp: '',
      cpRegla: [
        v => !!v || 'El Código Postal es Obligatorio.',
        v => (v && /[0-9]{5}/.test(v) || 'El Código Postal debe ser de 5 caracteres y solo permite números.'),
      ],
      pais: null,
      estado: null,
      ciudad: null,
      colonia: null,
      correo: '',
      telefono: '',
      celular: '',
      tipoContacto: null,
      reglaContacto: [],
      ministerio: null,
      congregacion: null,
      isBautizado: false,
      disableEncargado: true,
      isEncargado: false,
      ocupacion: null,
      ministerios: [],
      format: (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
            
        return `${year}-${month}-${day}`;
      },
      catalogos: {
        colonias: [],
        congregacion: [],
        ministerio: [],
        ocupacion: [],
        estadoCivil: [],
        tipoContacto: [{"id": 1, "nom_tipocontacto": "Whatsapp"}, {"id": 2, "nom_tipocontacto": "SMS"}],
        genero: [{"id": 1, "nom_genero": "Hombre"}, {"id": 2, "nom_genero": "Mujer"}]
      },
      disableColonia: true,
      loadingPais: false,
      loadingEstado: false,
      loadingCiudad: false,
      loadingColonia: false,
      loadLocalizacion: true,
      textAlert: '',
      showAlert: false,
      showAlertFecha: false,
      showAlertImg: false,
      loadingCongregacion: false,
      loadingMinisterio: false,
      loadingOcupacion: false,
      loadingEstadoCivil: false,
      loadingData: false,
      loadingMinisterioMiembro: false,
      isAdd: false,
      isEdit: false,
      isShow: false,
      idColonia: 0,
      idEstadoCivil: 0,
      idCongregacion: 0,
      idOcupacion: 0,
      idMiembro: 0,
      urlApiLocalizacion: process.env.VUE_APP_ENV_LOCALIZACION_API,
      urlApiCatalogos: process.env.VUE_APP_ENV_CATALOGOS_API,
      urlApi: process.env.VUE_APP_ENV_MIEMBROS_API
    };
  },

  mounted(){
    var self = this
    
    
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    self.maxDate = `${year}/${month}/${day}`;

    emitter.on('addMiembroModal',function(data){
      self.showModal = data.show
      self.isAdd = true
      self.loadCatalogos()
      self.loadMinisterios()
    });

    emitter.on('showMiembroModal',function(data){
      //console.log(data)
      self.showModal = data.show
      self.isEdit = data.isEdit
      self.isShow = !data.isEdit
      self.idMiembro = data.miembro.id
      self.nomImagen = data.miembro.des_imagen
      self.apellidoPaterno = data.miembro.nom_apellidoPaterno == null ? '' : data.miembro.nom_apellidoPaterno
      self.apellidoMaterno = data.miembro.nom_apellidoMaterno == null ? '' : data.miembro.nom_apellidoMaterno
      self.nombre = data.miembro.nom_miembro
      self.fecNacimiento = data.miembro.fec_nacimiento+"T00:00:00"
      self.domCalle = data.miembro.dom_calle
      self.domNumero = data.miembro.dom_numero
      self.cp = data.miembro.codigo_postal
      self.correo = data.miembro.des_correo == null ? '' : data.miembro.des_correo
      self.telefono = data.miembro.num_telefono == null ? '' : data.miembro.num_telefono
      self.celular = data.miembro.num_celular == null ? '' : data.miembro.num_celular
      self.idColonia = data.miembro.id_colonia
      self.idCongregacion = data.miembro.id_congregacion
      self.idEstadoCivil = data.miembro.id_estadoCivil
      self.idOcupacion = data.miembro.id_ocupacion
      self.isBautizado = data.miembro.bautizado == 1 ? true : false
      
      
      self.catalogos.genero.forEach(element => {
        if(element.id == data.miembro.id_genero){
          self.genero = element
        }
      });

      self.catalogos.tipoContacto.forEach(element => {
        if(element.id == data.miembro.id_tipoContacto){
          self.tipoContacto = element
        }
      });

      self.loadColonias()
      self.loadCatalogos()
      if(data.miembro.nom_ministerio != null){
        self.loadMinisteriosMiembro(data.miembro.id)
      }else{
        self.loadMinisterios()
        self.loadingMinisterioMiembro = true
      }
    });
    
    emitter.on('capturarImagenModalClosed',function(data){
      self.nomImagen = data.nomImagen
      //self.nomImagen = 'undefined'
      self.showAlertImg = false
    });
    
    emitter.on('searchCPModalClosed',function(data){
      self.loadingPais = true
      self.loadingEstado = true
      self.loadingCiudad = true 
      self.loadLocalizacion = false
      self.pais = data.pais
      self.loadingPais = false
      self.estado = data.estado
      self.loadingEstado = false
      self.ciudad = data.ciudad
      self.loadingCiudad = false
      self.colonia = data.colonia
      self.cp = data.cp
      self.loadColonias()
    });

    emitter.on('confirmaEncargadoModalClosed',function(data){
      //console.log(data)
      self.ministerio = data.ministerio
    })

  },

  computed: {
    loading(){
        if(this.loadingCongregacion && this.loadingEstadoCivil && this.loadingMinisterio && this.loadingOcupacion && ((this.loadingData && this.loadingMinisterioMiembro) || this.isAdd)){
            return false
        }else{
            return true
        }
    }
  },

  watch: {
    celular: function() {
      if(this.celular.length > 0){
        this.reglaContacto = [v => !!v || 'El Tipo de Contacto es Obligatorio.']
      }else{
        this.reglaContacto = []
      }
    },

    fecNacimiento: function() {
      if(this.fecNacimiento != ''){
        this.showAlertFecha = false
      }
    },

    ministerio: function() {
      if(this.ministerio == null || this.ministerio.length == 0 || this.isShow){
        this.disableEncargado = true
      }else{
        this.disableEncargado = false
      }
    }
  },

  methods: {
    close(){
      this.showModal = false
      this.nombre = ''
      this.nomImagen = 'undefined'
      this.apellidoPaterno = ''
      this.apellidoMaterno = ''
      this.fecNacimiento = ''
      this.estadoCivil = null
      this.genero = null
      this.domCalle = ''
      this.domNumero = ''
      this.cp = ''
      this.pais = null
      this.estado = null
      this.ciudad = null
      this.colonia = null
      this.correo = ''
      this.telefono = ''
      this.celular = ''
      this.tipoContacto = null
      this.ministerio = null
      this.congregacion = null
      this.isBautizado = false
      this.disableEncargado = true
      this.isEncargado = false
      this.ocupacion = null
      this.catalogos.colonias = []
      this.catalogos.congregacion = []
      this.catalogos.ministerio = []
      this.catalogos.estadoCivil = []
      this.catalogos.ocupacion = []
      this.disableColonia = true
      this.loadingPais = false
      this.loadingEstado = false
      this.loadingCiudad = false
      this.loadingColonia = false
      this.loadingData = false
      this.loadingMinisterioMiembro = false
      this.loadLocalizacion = true
      this.textAlert = ''
      this.showAlert = false
      this.showAlertFecha = false
      this.showAlertImg = false
      this.loadingCongregacion = false
      this.loadingMinisterio = false
      this.isAdd = false
      this.isShow = false
      this.isEdit = false
      this.idColonia = 0
      this.idEstadoCivil = 0
      this.idCongregacion = 0
      this.idOcupacion = 0
      this.idMiembro = 0
      this.ministerios = []
    },

    openSearchCPModal(){
      this.cp = ''
      this.catalogos.colonias = []

      var params = {
        show: true
      }

      emitter.emit("searchCPModal", params);
    },

    openCapturarImagenModal(){
      var params = {
        show: true
      }

      emitter.emit("capturarImagenModal", params);
    },

    searchCP() {
      if((this.catalogos.colonias.length != 0 && this.catalogos.colonias[0].codigo_postal != this.cp) || this.catalogos.colonias.length == 0){
        this.disableColonia = true
        this.pais = null
        this.estado = null
        this.ciudad = null
        this.colonia = null
        this.loadLocalizacion = true
        
        if(this.cp.length == 5){
          this.loadColonias()
        }else{
          this.openSearchCPModal()
        }
      }
    },

    loadColonias(){
      var self = this
      self.loadingColonia = true
      if(self.loadLocalizacion){
        self.loadingPais = true
        self.loadingEstado = true
        self.loadingCiudad = true    
      }

      var params = {
        codigo_postal: self.cp
      }

      axios.post(self.urlApiLocalizacion+"cp", params,{
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('SesionID')
        }
      }).then((result) => {
        if(result.data.data.colonias.length > 0){
          if(self.loadLocalizacion){
            self.pais = {id: result.data.data.datosLocalizacion[0].id_pais, nom_pais: result.data.data.datosLocalizacion[0].nom_pais}
            self.loadingPais = false
            self.estado = {id: result.data.data.datosLocalizacion[0].id_estado, nom_estado: result.data.data.datosLocalizacion[0].nom_estado}
            self.loadingEstado = false
            self.ciudad = {id: result.data.data.datosLocalizacion[0].id_municipio, nom_municipio: result.data.data.datosLocalizacion[0].nom_municipio}
            self.loadingCiudad = false
          }

          self.catalogos.colonias = result.data.data.colonias
          if(!self.isAdd){
            result.data.data.colonias.forEach(element => {
              if(element.id == self.idColonia){
                self.colonia = element
                self.loadingData = true
              }
            });
          }
          self.disableColonia = false
          self.loadingColonia = false
        }else{
          self.loadingColonia = false
          self.loadingPais = false
          self.loadingEstado = false
          self.loadingCiudad = false
          self.viewAlert("No se encontro el código postal. Favor de buscarlo por colonia.")
          self.openSearchCPModal()
        }
      }).catch(error=>{
        console.log(error)
      })
    },

    changeCP(){
      if(this.cp.length != 5 && this.catalogos.colonias.length != 0){
        this.colonia = null
        this.catalogos.colonias = []
        this.ciudad = null
        this.estado = null
        this.pais = null
      }
    },

    viewAlert(message){
      var self = this
      this.textAlert = message
      this.showAlert = true
      setTimeout(function(){
          self.showAlert = false
      }, 5000);
    },

    loadCatalogos(){
      this.loadCongregaciones()
      //this.loadMinisterios()
      this.loadOcupaciones()
      this.loadEstadosCiviles()
    },

    loadCongregaciones (){
      var self = this
      
      axios.get(self.urlApiCatalogos+"congregaciones", {'headers': {'Authorization': 'Bearer ' +localStorage.getItem('SesionID')}}).then((result) => {
        if(self.idCongregacionProp == 0){
          self.catalogos.congregacion = result.data.data
        }else{
          var tmp = result.data.data.find(({id}) => id == self.idCongregacionProp)
          self.catalogos.congregacion.push(tmp)
        }
        if(!self.isAdd){
          result.data.data.forEach(element => {
            if(element.id == self.idCongregacion){
              self.congregacion = element
            }
          });
        }
        self.loadingCongregacion = true
      }).catch(error=>{
        console.log(error)
      })
    },

    async loadMinisterios (){
      var self = this

      await axios.get(self.urlApiCatalogos+"ministerios", {'headers': {'Authorization': 'Bearer ' +localStorage.getItem('SesionID')}}).then((result) => {
        self.catalogos.ministerio = result.data.data
        self.loadingMinisterio = true
      }).catch(error=>{
        console.log(error)
      })
    },

    loadOcupaciones (){
      var self = this

      axios.get(self.urlApiCatalogos+"ocupaciones", {'headers': {'Authorization': 'Bearer ' +localStorage.getItem('SesionID')}}).then((result) => {
        self.catalogos.ocupacion = result.data.data
        if(!self.isAdd){
          result.data.data.forEach(element => {
            if(element.id == self.idOcupacion){
              self.ocupacion = element
            }
          });
        }
        self.loadingOcupacion = true
      }).catch(error=>{
        console.log(error)
      })
    },

    loadEstadosCiviles (){
      var self = this

      axios.get(self.urlApiCatalogos+"estadosCiviles", {'headers': {'Authorization': 'Bearer ' +localStorage.getItem('SesionID')}}).then((result) => {
        self.catalogos.estadoCivil = result.data.data
        if(!self.isAdd){
          result.data.data.forEach(element => {
            if(element.id == self.idEstadoCivil){
              self.estadoCivil = element
            }
          });
        }
        self.loadingEstadoCivil = true
      }).catch(error=>{
        console.log(error)
      })
    },

    changeEncargado() {
      if(this.isEncargado && this.ministerio.length > 1){
        var params = {
          show: true,
          ministerios: this.ministerio
        }

        emitter.emit("confirmaEncargadoModal", params);
      }else if(this.isEncargado && this.ministerio.length == 1){
        this.ministerio[0].isEncargado = 1
      }else if(!this.isEncargado){
        this.ministerio.forEach(element => {
          element.isEncargado = 0
        });
      }
    },

    changeMinisterio() {
      var auxEncargado = false
      var exist = true
      //console.log(this.ministerio)
      this.ministerio.forEach(element => {
        if(Object.hasOwn(element, 'isEncargado')){
          if(element.isEncargado == 1){
            auxEncargado = true
          }
        }else{
          element.isEncargado = 0
          exist = false
        }
      });

      if(this.isEncargado){
        this.isEncargado = auxEncargado
      }

      if(this.isEncargado && !exist){
        var params = {
          show: true,
          ministerios: this.ministerio
        }

        emitter.emit("confirmaEncargadoModal", params);
      }

    },

    async loadMinisteriosMiembro (idMiembro){
      var self = this
      self.ministerio = []
      await this.loadMinisterios()
      axios.get(self.urlApi+"ministerio/"+idMiembro, {'headers': {'Authorization': 'Bearer ' +localStorage.getItem('SesionID')}}).then((result) => {
        //console.log(result.data.data)
        self.ministerios = result.data.data
        result.data.data.forEach(element => {
          if(element.flag_encargado == 1 && !self.isEncargado){
            self.isEncargado = true
          }
          var aux = self.catalogos.ministerio.find(({id}) => id == element.id_ministerio)
          aux.isEncargado = element.flag_encargado
          self.ministerio.push(aux)
        });
        if(self.ministerio.length > 0 && self.isEdit){
          self.disableEncargado = false
        }
        self.loadingMinisterioMiembro = true
      }).catch(error=>{
        console.log(error)
      })
    },

    async save() {
      const { valid } = await this.$refs.form.validate()
      if (valid && this.fecNacimiento != '' && this.nomImagen != 'undefined' ){
        var self = this
        var params = {
          id_miembro: self.idMiembro,
          nom_miembro: self.nombre,
          nom_apellidoPaterno: self.apellidoPaterno,
          nom_apellidoMaterno: self.apellidoMaterno,
          id_genero: self.genero.id,
          fec_nacimiento: self.fecNacimiento,
          id_estadoCivil: self.estadoCivil.id,
          bautizado: self.isBautizado ? 1 : 0,
          des_correo: self.correo,
          num_telefono: self.telefono,
          num_celular: self.celular,
          dom_calle: self.domCalle,
          dom_numero: self.domNumero,
          codigo_postal: self.cp,
          id_pais: self.pais.id,
          id_estado: self.estado.id,
          id_municipio: self.ciudad.id,
          id_colonia: self.colonia.id,
          id_tipoContacto: self.tipoContacto == null ? 0 : self.tipoContacto.id,
          id_ocupacion: self.ocupacion == null ? 0 : self.ocupacion.id,
          des_imagen: self.nomImagen,
          id_congregacion: self.congregacion.id,
          ministerio: self.ministerio
        }
        //console.log(params)
        if(self.isAdd){
          axios.post(self.urlApi+"miembros", params,{
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('SesionID')
            }
          }).then((result) => {
            emitter.emit("addMiembroModalClosed", result.data);
            self.close()
          }).catch(error=>{
            console.log(error)
          });
        }else if(self.isEdit){
          self.ministerios.forEach(origen => {
            origen.inactivo = 1
            origen.isUpdate = 1
            self.ministerio.forEach(cargado => {
              if(origen.id_ministerio == cargado.id){
                origen.inactivo = 0
                origen.isUpdate = 0
                if(origen.flag_encargado != cargado.isEncargado){
                  origen.flag_encargado = cargado.isEncargado
                  origen.isUpdate = 1
                }
              }
            });
          });

          if(self.ministerio != null){
            self.ministerio.forEach(element => {
              var aux = self.ministerios.find(({id_ministerio}) => id_ministerio == element.id)
              if(aux == undefined){
                self.ministerios.push({id: 0, id_ministerio: element.id, flag_encargado: element.isEncargado})
              }
            });
          }
          
          params.ministerio = self.ministerios;
          //console.log(params)
          axios.put(self.urlApi+"miembros", params,{
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('SesionID')
            }
          }).then((result) => {
            emitter.emit("addMiembroModalClosed", result.data);
            self.close()
          }).catch(error=>{
            console.log(error)
          });
        }


      }else if(this.fecNacimiento == '' || this.nomImagen == 'undefined'){
        if(this.fecNacimiento == ''){
          this.showAlertFecha = true
        }
        if(this.nomImagen == 'undefined'){
          this.showAlertImg = true
        }
      }
    }
  }
};
</script>

<style>
  .date {
    border: 0 !important;
    border-bottom: 2px !important;
  }
</style>
