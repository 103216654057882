<template>
    <v-row justify="center">
      <v-dialog
        v-model="showModal"
        persistent
        max-width="1200"
      >
        <v-card class="mx-auto" width="1100">
          <v-skeleton-loader
            v-if="loading"
            type="list-item-three-line, actions"
          ></v-skeleton-loader>
          <div v-else>
            <v-card-title>
              <span class="text-h5">Capturar Imagen</span>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col class="col-md-5">
                  <vue-web-cam
                    ref="webcam"
                    :device-id="deviceId"
                    :width="480"
                    :height="350"
                    @error="onError"
                    @cameras="onCameras"
                    @camera-change="onCameraChange"
                  />
                </v-col>
                <v-col class="col-md-5">
                  <figure class="figure">
                    <img :src="img" class="img-responsive" />
                  </figure>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn :disabled="loadSave" icon="mdi-camera-outline" variant="text" color="info" size="x-large" @click="onCapture()"></v-btn>
              <v-btn :disabled="loadSave" icon="mdi-camera-flip-outline" variant="text" color="info" size="x-large" @click="cambiarCamara()"></v-btn>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="loadSave"
                color="info"
                text
                @click="close()"
              >
                Cancelar
              </v-btn>
              <v-btn
                :loading="loadSave"
                color="info"
                text
                v-if="img==null ? false : true"
                @click="guardarImagen()"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  
  <script>
import WebCam  from "../webcam/webcam.vue";
import emitter from "../../plugins/mitt";
import axios from "axios";

export default {
  name: "App",
  components: {
    "vue-web-cam": WebCam
  },
  
  data() {
    return {
      showModal: false,
      loading: false,
      loadSave: false,
      img: null,
      nomImagen: '',
      camera: null,
      deviceId: null,
      devices: [],
      urlApi: process.env.VUE_APP_ENV_MIEMBROS_API
    };
  },
    
  computed: {
    device: function() {
      return this.devices.find(n => n.deviceId === this.deviceId);
    }
  },
  
  watch: {
    camera: function(id) {
      this.deviceId = id;
    },
    devices: function() {
      const [first, ...tail] = this.devices;
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    }
  },

  mounted(){
    var self = this
      
    emitter.on('capturarImagenModal',function(data){
      self.loading = true
      self.showModal = data.show
      if(self.camera = null){
        self.onStart()
      }
      self.loading = false
    });
  },
    
  methods: {
    onCapture() {
      this.img = this.$refs.webcam.capture();
    },
      
    onStop() {
      this.$refs.webcam.stop();
    },
      
    onStart() {
      this.$refs.webcam.start();
    },
      
    onError(error) {
      console.log("Error Event", error);
    },
      
    onCameras(cameras) {
      this.devices = cameras;
    },
      
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
    },

    cambiarCamara() {
      const device = (element) => element.deviceId == this.deviceId;
      var i = this.devices.findIndex(device);

      if(i<this.devices.length-1){
        this.onCameraChange(this.devices[i+1].deviceId)
      }else{
        this.onCameraChange(this.devices[0].deviceId)
      }
    },

    guardarImagen(){
      var self = this
      var params = {
        imagen: self.img
      }

      self.loadSave = true
      axios.post(self.urlApi+"imgMiembro", params, {'headers': {'Authorization': 'Bearer ' +localStorage.getItem('SesionID')}}).then((result) => {
        self.nomImagen = result.data.data
        
        var param = {
          nomImagen: self.nomImagen
        }
        emitter.emit("capturarImagenModalClosed", param);
        self.close()
      }).catch(error=>{
        self.loadSave = false
        console.log(error)
      })
    },

    close(){
      this.onStop()
      this.img = null
      this.showModal = false
      this.nomImagen = ''
      this.loading = false
      this.loadSave = false
    }
  }
};
</script>
  